import { inject, provide, Ref, ref, watch } from '@vue/composition-api';
import { ErrorStatus } from '@/types/common';
import { container } from 'tsyringe';
import { ExportCsvService } from '@/models/service/Management/ExportCsvService';
import { FetchPaymentCompanyListService } from '@/models/service/Management/FetchPaymentCompanyListService';
import { PaymentCompanyInfo } from '@/models/interface/Management/IPaymentRepository';
import { useDate } from '@/composables/function/Common/useDate';
import { isFailedResponse } from '@/models/interface/common';
import { useCircular } from '@/composables/function/Common/useCircular';

const ExportCsv = container.resolve(ExportCsvService);
const PaymentCompanyList = container.resolve(FetchPaymentCompanyListService);

const { getYesterday, getLastMonth, getFirstOfMonth, getEndOfMonth, convertToPeriodFormat } = useDate();

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideUsageBill = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const selectedMonth = ref<string>(getLastMonth());
    const paymentCompanyList = ref<PaymentCompanyInfo[]>();
    const isPaymentCompanyFetchProgressing = ref(false);
    const isExportCsvProgressing = ref(false);
    const targetPeriod = ref<string>(convertToPeriodFormat(new Date(selectedMonth.value)));

    provide('error', error);
    provide('selectedMonth', selectedMonth);
    provide('paymentCompanyList', paymentCompanyList);
    provide('isPaymentCompanyFetchProgressing', isPaymentCompanyFetchProgressing);
    provide('isExportCsvProgressing', isExportCsvProgressing);
    provide('targetPeriod', targetPeriod);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useUsageBill = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const selectedMonth = inject('selectedMonth') as Ref<string>;
    const paymentCompanyList = inject('paymentCompanyList') as Ref<PaymentCompanyInfo[]>;
    const isPaymentCompanyFetchProgressing = inject('isPaymentCompanyFetchProgressing') as Ref<boolean>;
    const isExportCsvProgressing = inject('isExportCsvProgressing') as Ref<boolean>;
    const targetPeriod = inject('targetPeriod') as Ref<string>;

    const { progressStart, progressEnd } = useCircular();

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const fetchList = async (): Promise<void> => {
        progressStart(isPaymentCompanyFetchProgressing);
        const response = await PaymentCompanyList.handle(getStartDate(), getEndDate());

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            return;
        }

        initStatus();
        progressEnd(isPaymentCompanyFetchProgressing);
        paymentCompanyList.value = response.data.data;
    };

    const exportCsv = async (): Promise<void> => {
        progressStart(isExportCsvProgressing);

        await ExportCsv.handle(getStartDate(), getEndDate());

        initStatus();
        progressEnd(isExportCsvProgressing);
    };

    const getStartDate = (): string => {
        return getFirstOfMonth(new Date(selectedMonth.value));
    };

    const getEndDate = (): string => {
        const endOfMonth = getEndOfMonth(new Date(selectedMonth.value));
        return new Date(getYesterday()) <= new Date(endOfMonth) ? getYesterday() : endOfMonth;
    };

    watch(selectedMonth, () => {
        targetPeriod.value = convertToPeriodFormat(new Date(selectedMonth.value));
    });

    return {
        error,
        selectedMonth,
        paymentCompanyList,
        exportCsv,
        fetchList,
        isPaymentCompanyFetchProgressing,
        isExportCsvProgressing,
        targetPeriod,
    };
};
