


































import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';
import PaymentCompanyListComponent from '@/components/Management/organisms/PaymentCompanyList.vue';
import { onMounted, watch } from '@vue/composition-api';
import TargetMonthPickerComponent from '@/components/Management/molecules/TargetMonthPicker.vue';
import { useUsageBill } from '@/composables/store/Management/useUsageBill';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import CsvExportButtonComponent from '@/components/Management/molecules/CsvExportButton.vue';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        PaymentCompanyList: PaymentCompanyListComponent,
        CsvExportButton: CsvExportButtonComponent,
        TargetMonthPicker: TargetMonthPickerComponent,
    },
    setup() {
        const {
            fetchList,
            error,
            selectedMonth,
            isPaymentCompanyFetchProgressing,
            isExportCsvProgressing,
            paymentCompanyList,
        } = useUsageBill();

        onMounted((): void => {
            fetchList();
        });

        watch(selectedMonth, () => {
            fetchList();
        });

        return {
            error,
            selectedMonth,
            isPaymentCompanyFetchProgressing,
            isExportCsvProgressing,
            paymentCompanyList,
        };
    },
});
