import { inject, injectable } from 'tsyringe';
import { IPaymentRepository, PaymentCompanyFetchResponse } from '@/models/interface/Management/IPaymentRepository';

@injectable()
export class FetchPaymentCompanyListService {
    constructor(@inject('IPaymentRepository') private readonly repository: IPaymentRepository) {}

    async handle(startDate: string, endDate: string): Promise<PaymentCompanyFetchResponse> {
        return this.repository.getPaymentCompanyIndex(startDate, endDate);
    }
}
