









import { useUsageBill } from '@/composables/store/Management/useUsageBill';
import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';

export default defineComponent({
    setup() {
        const { exportCsv, selectedMonth } = useUsageBill();

        return {
            exportCsv,
            selectedMonth,
        };
    },
});
