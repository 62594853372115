
































import { defineComponent, ref } from '@vue/composition-api';
import { useUsageBill } from '@/composables/store/Management/useUsageBill';
import { useDate } from '@/composables/function/Common/useDate';

export default defineComponent({
    setup() {
        const menu = ref(false);

        const { selectedMonth, targetPeriod } = useUsageBill();

        const { getYesterday } = useDate();

        return {
            menu,
            selectedMonth,
            targetPeriod,
            getYesterday,
        };
    },
});
