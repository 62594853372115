import { inject, injectable } from 'tsyringe';
import { IPaymentRepository } from '@/models/interface/Management/IPaymentRepository';

@injectable()
export class ExportCsvService {
    constructor(@inject('IPaymentRepository') private readonly repository: IPaymentRepository) {}

    async handle(startDate: string, endDate: string): Promise<void> {
        return this.repository.getPaymentCsv(startDate, endDate);
    }
}
