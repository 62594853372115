

















































import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';
import { useUsageBill } from '@/composables/store/Management/useUsageBill';

export default defineComponent({
    setup() {
        const { paymentCompanyList, isPaymentCompanyFetchProgressing } = useUsageBill();

        return {
            isPaymentCompanyFetchProgressing,
            paymentCompanyList,
        };
    },
});
